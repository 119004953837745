<template>
  <router-view />
</template>

<script>
export default {
  name: 'InventoryView',
  computed: {
    warehouse() {
      return this.$store.state.app.warehouse
    },
  },
  watch: {
    warehouse: {
      handler(newValue, oldValue) {
        if (newValue.id !== oldValue.id) {
          this.fetchWarehouses()
        }
      },
      deep: true,
    },
  },
  async created() {
    this.$emit('updateMenu', 'home-inventory-list')

    // Getting Inventory ID(Product ID)
    this.$store.commit(`${this.MODULE_NAME}/SET_INVENTORY_ID`, this.$route.params.id)

    // Getting Inventory(Product)
    await this.$store.dispatch(`${this.MODULE_NAME}/getStockProduct`, { id: this.$route.params.id })
      .then(res => {
        const { data } = res.data

        this.$store.commit(`${this.MODULE_NAME}/GET_PRODUCT`, data)
        this.$store.commit(`${this.MODULE_NAME}/SET_INVENTORY_SERIALIZED_AND_NON_SERIALIZED_VAL`, data.detail.needs_individual_asset_id)
      })
    // Getting Warehouse(Warehouse)
    this.fetchWarehouses()
  },
  methods: {
    fetchWarehouses() {
      this.$store.dispatch(`${this.MODULE_NAME}/getWarehouses`, { id: this.$store.state.app.warehouse.id })
        .then(res => {
          const { data } = res.data

          // eslint-disable-next-line no-unused-vars
          const locations = []
          const fun = (node, label) => {
            // eslint-disable-next-line no-param-reassign
            node.optionLabel = label.join('-')
            const children = []
            if (node.children.length) {
              node.children.forEach(item => {
                const val = fun(item, label.concat(item.level_name))
                children.push(val)
              })
            }
            // eslint-disable-next-line no-shadow
            const res = {
              id: node.id,
              label: node.level_name,
              optionLabel: label.join(', '),
              disabled: !node.is_active,
            }

            if (children.length > 0) {
              res.children = children
            }
            return res
          }

          data.locations.forEach(item => {
            locations.push(fun(item, [item.level_name]))
          })

          data.locations = locations
          this.$store.commit(`${this.MODULE_NAME}/SET_FOR_INVENTORY_LOCATION_WAREHOUSES`, data)
        })
    },
  },
  setup() {
    const MODULE_NAME = 'inventories'

    return {
      MODULE_NAME,
    }
  },
}
</script>
